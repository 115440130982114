<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Report Format'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="reportFormatOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.reportFormat"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    <app-run-as-input
      [name]="'runAs'"
      [control]="formGroup?.controls?.runAs"
      [viewMode]="fieldViewMode"
      class="wrapped-input-component"
    >
    </app-run-as-input>
    <div class="col-12 md:col-6">
      <app-language-selector
        [label]="moduleFieldString + '.lang.label' | translate: 'Language'"
        [placeholder]="moduleFieldString + '.lang.placeholder' | translate: 'Enter Language'"
        [control]="formGroup?.controls?.language"
        [viewMode]="fieldViewMode"
        [optionValue]="'code'"
      >
      </app-language-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-palette-picker-input
        [label]="'Color Palette'"
        [placeholder]="'Select Palette'"
        [name]="'colorPalette'"
        [control]="formGroup?.controls?.colorPalette"
        [viewMode]="fieldViewMode"
        [items]="primarycolorPalleteOptions"
      ></app-palette-picker-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Theme'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="themeOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.theme"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Page Location'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="pageLocationOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.pageLocation"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'watermark'"
        label="Watermark"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.watermark"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
    <div class="col-12 md:col-6">
      <app-p-slider-input
        [name]="'watermark'"
        label="Watermark "
        [placeholder]="'Enter Watermark Opacity'"
        [control]="formGroup?.controls?.watermarkOpacity"
        [viewMode]="fieldViewMode"
        [step]="0.05"
        [max]="1"
      ></app-p-slider-input>
    </div>
    <p-divider class="w-full" align="left">
      <b>Report Details</b>
    </p-divider>
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Show Date of Generation'"
        [name]="'exportRaw'"
        [control]="reportDetailsForm?.controls?.generatedAt"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Show generated As'"
        [name]="'exportRaw'"
        [control]="reportDetailsForm?.controls?.generatedAs"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Show Creator'"
        [name]="'exportRaw'"
        [control]="reportDetailsForm?.controls?.generatedUser"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <p-divider class="w-full" align="left">
      <b>PDF Configuration</b>
    </p-divider>
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Export Raw'"
        [name]="'exportRaw'"
        [control]="pdfRenderConfigForm?.controls?.exportRaw"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'PDF Size'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="pdfSizeOptions"
        [optionValue]="'value'"
        [control]="pdfRenderConfigForm?.controls?.pdfSize"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Include Table Of Content'"
        [name]="'includeTableOfContents'"
        [control]="pdfRenderConfigForm?.controls?.includeTableOfContents"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Include Report Details'"
        [name]="'includeReportDetails'"
        [control]="pdfRenderConfigForm?.controls?.includeReportDetails"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <div class="col-12 md:col-6">
      <app-number-input
        [label]="'Header Height'"
        [placeholder]="'Enter Header Height in px'"
        [name]="'headerHieght'"
        [showButtons]="true"
        [control]="pdfRenderConfigForm?.controls?.headerHeight"
        [type]="'number'"
        [viewMode]="fieldViewMode"
        [maxFractionDigits]="0"
      ></app-number-input>
    </div>
    <div class="col-12 md:col-6">
      <app-number-input
        [label]="'Footer Height'"
        [placeholder]="'Enter Footer Height in px'"
        [name]="'footerHieght'"
        [showButtons]="true"
        [control]="pdfRenderConfigForm?.controls?.footerHeight"
        [type]="'number'"
        [viewMode]="fieldViewMode"
        [maxFractionDigits]="0"
      ></app-number-input>
    </div>

    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Include Header'"
        [name]="'includeHeader'"
        [control]="pdfRenderConfigForm?.controls?.includeHeader"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    @if (pdfRenderConfigForm?.controls?.includeHeader?.value === true) {
      <div class="col-12 md:col-12">
        <app-text-editor
          [name]="'header'"
          label="Header"
          [placeholder]="'Enter Text...'"
          [control]="pdfRenderConfigForm?.controls?.header"
          [viewMode]="fieldViewMode"
          [advanced]="true"
        ></app-text-editor>
      </div>
    }
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Include Footer'"
        [name]="'includeFooter'"
        [control]="pdfRenderConfigForm?.controls?.includeFooter"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    @if (pdfRenderConfigForm?.controls?.includeFooter?.value === true) {
      <div class="col-12 md:col-12">
        <app-text-editor
          [name]="'footer'"
          label="Footer"
          [placeholder]="'Enter Text...'"
          [control]="pdfRenderConfigForm?.controls?.footer"
          [viewMode]="fieldViewMode"
          [advanced]="true"
        ></app-text-editor>
      </div>
    }
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Include Cover Page'"
        [name]="'includeCoverPage'"
        [control]="pdfRenderConfigForm?.controls?.includeCoverPage"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    @if (pdfRenderConfigForm?.controls?.includeCoverPage?.value === true) {
      <div class="col-12 md:col-12">
        <app-text-editor
          [name]="'coverPage'"
          label="Cover Page"
          [placeholder]="'Enter Text...'"
          [control]="pdfRenderConfigForm?.controls?.coverPage"
          [viewMode]="fieldViewMode"
          [advanced]="true"
        ></app-text-editor>
      </div>
    }
    <!-- <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div> -->
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center mt-2">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>

<div class="sf-report-content-wrapper">
  <section class="sf-report-section-wrapper" id="section-id">
    <div class="sf-report-section-title-wrapper"></div>
    <div class="sf-report-section-content-wrapper">
      <div class="sf-report-[content-view-type]-wrapper">
        <div class="sf-report-[chart-type]-wrapper sf-report-[variation]-wrapper"></div>
        <div class="sf-report-aggergation-table-wrapper"></div>
      </div>
    </div>
  </section>
</div>
