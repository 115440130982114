import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, getEnumOptions, PDFRenderConfig, ReportConfigurationTemplateDto } from '@shared/classes';
import { PrimaryColorPalettes } from '@shared/components/ui/input-fields/palette-picker-input/palette-picker-input.component';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-report-configuration-template-item-form',
  templateUrl: './report-configuration-template-item-form.component.html',
  styleUrl: './report-configuration-template-item-form.component.scss',
})
export class ReportConfigurationTemplateItemFormComponent
  extends BaseForm<ReportConfigurationTemplateDto>
  implements OnInit
{
  reportFormatOptions = getEnumOptions(ReportConfigurationTemplateDto.ReportFormatEnum);
  pdfSizeOptions = getEnumOptions(PDFRenderConfig.PdfSizeEnum);
  themeOptions = getEnumOptions(ReportConfigurationTemplateDto.ThemeEnum);
  pageLocationOptions = getEnumOptions(ReportConfigurationTemplateDto.PageLocationEnum);
  primarycolorPalleteOptions = Object.entries(PrimaryColorPalettes).map(([key, value]) => {
    return { label: key, value: value };
  });
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'REPORT_CONFIGURATION_TEMPLATE');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat({ ...this.formGroup.getRawValue() })
      : this.getChangedFormValues({ ...this.formGroup.getRawValue() }).updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null),
      // description: new FormControl(null),
      reportFormat: new FormControl(ReportConfigurationTemplateDto.ReportFormatEnum.Pdf),
      runAs: new FormControl(null), //@TODO: add run
      language: new FormControl(null),
      theme: new FormControl(ReportConfigurationTemplateDto.ThemeEnum.Light),
      colorPalette: new FormControl(PrimaryColorPalettes.default),
      watermark: new FormControl(null),
      watermarkOpacity: new FormControl(0.3),
      pageLocation: new FormControl(ReportConfigurationTemplateDto.PageLocationEnum.BottomLeft),
      reportDetails: new FormGroup({
        generatedAt: new FormControl(false),
        generatedAs: new FormControl(false),
        generatedUser: new FormControl(false),
      }),
      pdfRenderConfig: new FormGroup({
        pdfSize: new FormControl(PDFRenderConfig.PdfSizeEnum.A4),
        header: new FormControl(null),
        footer: new FormControl(null),
        coverPage: new FormControl(null),
        includeHeader: new FormControl(false),
        includeFooter: new FormControl(false),
        includeCoverPage: new FormControl(false),
        includeTableOfContents: new FormControl(false),
        includeReportDetails: new FormControl(false),
        exportRaw: new FormControl(false),
        headerHeight: new FormControl(80),
        footerHeight: new FormControl(50),
      }),
    });
  }
  get pdfRenderConfigForm() {
    return this.formGroup.controls.pdfRenderConfig as FormGroup<{
      pdfSize: FormControl<any>;
      header: FormControl<any>;
      footer: FormControl<any>;
      coverPage: FormControl<any>;
      includeHeader: FormControl<any>;
      includeFooter: FormControl<any>;
      includeCoverPage: FormControl<any>;
      includeTableOfContents: FormControl<any>;
      includeReportDetails: FormControl<any>;
      exportRaw: FormControl<any>;
      headerHeight: FormControl<any>;
      footerHeight: FormControl<any>;
    }>;
  }
  get reportDetailsForm() {
    return this.formGroup.controls.reportDetails as FormGroup<{
      generatedAt: FormControl<boolean>;
      generatedAs: FormControl<boolean>;
      generatedUser: FormControl<boolean>;
    }>;
  }
}
